<template>
  <div class="go_register">
    <div class="go_register_top">
      <topHeader></topHeader>
    </div>
    <div class="content">
      <div
        class="content_white no_worker"
        flex
        center
        v-if="common_status === 0"
      >
        <div class="out_box">
          <div class="title">成为作家，你能获得：</div>
          <p flex>
            <span class="list_title one">1</span>
            <span>上传自己的作品</span>
          </p>
          <p flex>
            <span class="list_title two">2</span>
            <span>和自己喜欢的作家结成师徒</span>
          </p>
          <p flex>
            <span class="list_title three">3</span>
            <span>获得与平台签约的机会，赚取稿费</span>
          </p>
          <div class="btm_btn">
            <el-button
              type="primary"
              style="padding: 8px 15px"
              @click="
                person_info.audit_status === 1
                  ? ''
                  : $router.push({ path: '/write_detail' })
              "
              >{{
                person_info.audit_status === 1 ? "审核中" : "立即成为作家"
              }}</el-button
            >
          </div>
        </div>
      </div>
      <div
        class="content_white"
        v-if="person_info.audit_status === 1"
        flex
        align
      >
        <!-- v-if="common_status===2" -->
        <div class="out_box">
          <div class="title">成为作家，你能获得：</div>
          <p flex>
            <span class="list_title one">1</span>
            <span>上传自己的作品</span>
          </p>
          <p flex>
            <span class="list_title two">2</span>
            <span>和自己喜欢的作家结成师徒</span>
          </p>
          <p flex>
            <span class="list_title three">3</span>
            <span>获得与平台签约的机会，赚取稿费</span>
          </p>
          <div class="status_word">
            已提交申请,正在审核中...(1~2个工作日内完成审核)
          </div>
        </div>
      </div>
      <div
        class="content_white"
        v-if="person_info.audit_status === -1"
        flex
        align
      >
        <!-- v-if="common_status===2" -->
        <div class="out_box">
          <div class="title">成为作家，你能获得：</div>
          <p flex>
            <span class="list_title one">1</span>
            <span>上传自己的作品</span>
          </p>
          <p flex>
            <span class="list_title two">2</span>
            <span>和自己喜欢的作家结成师徒</span>
          </p>
          <p flex>
            <span class="list_title three">3</span>
            <span>获得与平台签约的机会，赚取稿费</span>
          </p>
          <div class="status_word">
            申请已被拒绝，<span
              class="reset_go"
              @click="$router.push({ path: '/write_detail' })"
              >重新申请</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import topHeader from "@/components/main/top-header";
// import {MoneyInfo} from '@https/mine_center.js';
export default {
  name: "go_register",
  components: {
    topHeader,
  },
  data() {
    return {
      person_info: JSON.parse(sessionStorage.getItem("person_info")),
      common_status: Number(
        JSON.parse(sessionStorage.getItem("person_info")).audit_status
      ),
    };
  },
  created() {
    this.person_info = JSON.parse(sessionStorage.getItem("person_info"));
  },
};
</script>
<style lang="scss" scoped>
@import "../../utils/tool.scss";
//  @include flex-row();
.go_register {
  background-color: #fafcff;
  .go_register_top {
    width: 100%;
    height: 60px;
    background-color: #fff;
    box-shadow: 5px 0 5px #d2d2d2;
    .top_header {
      // background-color: rgba(248, 243, 233, 1);
      background-color: #ffffff;
      height: 56px;
      /deep/.el-input__inner {
        height: 36px !important;
        border-radius: 50px !important;
      }
      /deep/.input_arr {
        height: 36px;
        line-height: 36px;
      }
      /deep/.seach {
        width: 45px;
        background: #eeeeee;
        color: #999999;
        font-size: 20px;
        height: 36px;
        border-radius: 0 50px 50px 0 !important;
      }
    }
  }
}
.content {
  min-height: calc(100vh - 60px);
  padding-top: 20px;
  .content_white {
    background-color: #fff;
    width: 950px;
    min-height: calc(100vh - 80px);
    border-radius: 4px;
    margin: 0 auto;
    box-shadow: 0 0 5px #e4e4e4;
    display: flex;
    flex-direction: column;
  }
  .out_box {
    @include flex-column-left();
    width: 35%;
    .title {
      font-weight: bold;
      font-size: 16px;
      padding: 80px 0 0;
    }
    > p {
      width: 100%;
      font-size: 14px;
      padding: 30px 0;
      .list_title {
        width: 20px;
        height: 20px;
        text-align: center;
        border-radius: 50%;
        background-color: #0c2962;
        color: #fff;
        margin-right: 10px;
        border: 1px solid #0c2962;
      }
    }
    .btm_btn {
      width: 100%;
      text-align: center;
    }
  }
}
.reset_go {
  color: #409eff;
  cursor: pointer;
}
.status_word {
  padding-top: 60px;
}
.no_worker {
  align-items: center;
  .title {
    padding: 0;
  }
}
</style>

